import React, {useContext} from "react";
import { ApplicationContext } from "../../../../context/ApplicationContextProvider";

const InsuranceInvoice = () => {
  const { invoiceData } = useContext(ApplicationContext);
  console.log(invoiceData);
  return (
    <>
      <div>
        <h1 className="pl-4 pt-4 pb-6 text-xl font-extrabold">Driver Insurance</h1>
      </div>
      <div>
        <h3 className="px-4 text-sm text-gray-400">Insurance ID</h3>
        <p className="px-4 text-sm">{invoiceData.insurance_id}</p>
      </div>
      <div>
        <h3 className="px-4 pt-4 text-sm text-gray-400">Validity</h3>
        <p className="px-4 text-sm">{invoiceData.validity}</p>
      </div>
      <div>
        <h3 className="px-4 pt-4 text-sm text-gray-400">What is covered?</h3>
        <div className="pl-4">
          <ul className="px-4  list-disc pl-4">
            <li className="pb-2 text-sm">
              Please do ‘Take booking’ only when you are sure of completing the
              booking{" "}
            </li>
            <li className="text-sm">This score is calculated everyday</li>
          </ul>
        </div>
      </div>
      <div>
        <h3 className="px-4 pt-4 text-sm text-gray-400">Claim Process</h3>
        <div className="pl-4">
        <ul className="px-4  list-disc pl-4 ">
          <li className="pb-2 text-sm">
            Please do ‘Take booking’ only when you are sure of completing the
            booking
          </li>
          <li className="text-sm">This score is calculated everyday</li>
        </ul>
        </div>

      </div>
    </>
  );
};

export default InsuranceInvoice;
