import React from 'react'

const InsuranceFromFill = () => {
  return (
    <div className="mt-3">
      <h2 className=" text-xl pt-4 pb-1 px-4 font-extrabold">Driver Insurance</h2>
      <p className="px-4 mt-3">Someone from DriveU Team will get in touch with you</p>
    </div>
  )
}

export default InsuranceFromFill
